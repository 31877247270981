import './App.css';

import React, {useEffect} from 'react';
import { MsalProvider, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { PageLayout } from './components/PageLayout';
import { Dashboard } from './components/Dashboard';
import { Home } from './Home';

import { b2cPolicies, protectedResources } from './authConfig';

const Pages = () => {
  const { instance } = useMsal();
  useEffect(() => {
      const callbackId = instance.addEventCallback((event) => {
          if (
              (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
              event.payload.account
          ) {
              /**
               * For the purpose of setting an active account for UI update, we want to consider only the auth
               * response resulting from SUSI flow. "tfp" claim in the id token tells us the policy (NOTE: legacy
               * policies may use "acr" instead of "tfp"). To learn more about B2C tokens, visit:
               * https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
               */
              if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.editProfile) {
                  // retrieve the account from initial sing-in to the app
                  const originalSignInAccount = instance
                      .getAllAccounts()
                      .find(
                          (account) =>
                              account.idTokenClaims.oid === event.payload.idTokenClaims.oid &&
                              account.idTokenClaims.sub === event.payload.idTokenClaims.sub &&
                              account.idTokenClaims['tfp'] === b2cPolicies.names.signUpSignIn
                      );

                  let signUpSignInFlowRequest = {
                      authority: b2cPolicies.authorities.signUpSignIn.authority,
                      account: originalSignInAccount,
                  };

                  // silently login again with the signUpSignIn policy
                  instance.ssoSilent(signUpSignInFlowRequest);
              }

              /**
               * Below we are checking if the user is returning from the reset password flow.
               * If so, we will ask the user to reauthenticate with their new password.
               * If you do not want this behavior and prefer your users to stay signed in instead,
               * you can replace the code below with the same pattern used for handling the return from
               * profile edit flow
               */
              if (event.payload.idTokenClaims['tfp'] === b2cPolicies.names.forgotPassword) {
                  let signUpSignInFlowRequest = {
                      authority: b2cPolicies.authorities.signUpSignIn.authority,
                      scopes: [
                          ...protectedResources.apiTodoList.scopes.read,
                          ...protectedResources.apiTodoList.scopes.write,
                      ],
                  };
                  instance.loginRedirect(signUpSignInFlowRequest);
              }
          }

          if (event.eventType === EventType.LOGIN_FAILURE) {
              // Check for forgot password error
              // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
              if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
                  const resetPasswordRequest = {
                      authority: b2cPolicies.authorities.forgotPassword.authority,
                      scopes: [],
                  };
                  instance.loginRedirect(resetPasswordRequest);
              }
          }
      });

      return () => {
          if (callbackId) {
              instance.removeEventCallback(callbackId);
          }
      };
      // eslint-disable-next-line
  }, [instance]);

  return(
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<Home />} />
      </Routes>
  </BrowserRouter>
  )
}

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <PageLayout>
          <Pages />
      </PageLayout>
  </MsalProvider>
  );
}

export default App;
